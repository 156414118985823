<template lang="html">
  <div class="m-5">
    <h5>Description:</h5>
    <p>{{desc}}</p>
  </div>


</template>

<script>
export default {
  name: "DescBox",
  props: ['desc']
}
</script>

<style lang="css" scoped>
h5{
  text-align: left;
}

</style>
