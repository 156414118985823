<template>
  <div class="d-flex flex-column align-items-center">


    <img class="image border border-light mt-3" src="../assets/mars_WebOptimized.jpg">
    <div class="mb-5">
        <Weather/>
    </div>
</div>
</template>

<script>
import Weather from '@/components/Weather.vue'

export default
  {
    name: 'home',

    components:
      {
        Weather
      }
  }
</script>

<style lang="css" scoped>
.image
  {
    padding: 10px;
    width: 70em;
    max-width: 100%;
    height: 20%;
  }
</style>
