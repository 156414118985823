<template lang="html">
  <div class="flex-column">
    <div class="d-flex flex-wrap justify-content-center">
      <img class="image" src="../assets/turtle_WebOptimized.jpg">
      <img class="image" src="../assets/big_roof_WebOptimized.jpg">
      <img class="image" src="../assets/walnut_sandstone_WebOptimized.jpg">
      <img class="image" src="../assets/weird_limestone_WebOptimized.jpg">
      <img class="image" src="../assets/crazy_limestone_WebOptimized.jpg">
      <img class="image" src="../assets/good_limestone_WebOptimized.jpg">
      <img class="image" src="../assets/mars_WebOptimized.jpg">
      <img class="image" src="../assets/yuichiro_WebOptimized.jpg">
      <img class="image" src="../assets/267roof_WebOptimized.jpg">
    </div>
  </div>
</template>

<script>
export default
  {
    name: "Gallery"
  }
</script>

<style lang="css"  scoped>
.image
  {
    padding: 10px;
    width: 30em;
    max-width: 100%;
    height: 20%;
  }

</style>
