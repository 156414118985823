<template>
  <div class=" container bg-dark ">

    <!-- displays all the sub areas for this specific area -->
     <div class="d-flex justify-content-around" v-for=" sub_area in sub_areas">
       <router-link :to="{ name: 'SubAreaDetails', params: { id: sub_area.id } }">
            <h5>{{ sub_area.name }}</h5>
       </router-link>
    </div>
  </div>

</template>

<script>

export default {
  name: "AreaSubAreasBox",
  props: ["sub_areas"],

  }

</script>

<style lang="css" scoped>
a:link  {text-decoration: none;}
</style>
