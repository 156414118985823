<template lang="html">
    <div class="bottom container-fluid d-flex flex-wrap justify-content-center">

      <div
      v-if="weathers"
       v-for="(weather,index) in weathers.daily"
      >

      <WeatherCard
      :day="getDayName(index)" :month="getMonthPost()" :date="getDatePost(index)"
      :high="Math.round(weather.temp.max)" :low="Math.round(weather.temp.min)"
      :main="getWeatherMain(index)" :description="getWeatherDescription(index)"
      :icon="getWeatherIcon(index)"
      />

      </div>

    </div>
</template>

<script>

import WeatherCard from '@/components/WeatherCard.vue'

export default {
  name: 'Weather',
  components: {
    WeatherCard
  },


  data()
    {

      return {

        Climates:
        [
          {weather: "rain", icon: require('@/assets/rain.png')},
          {weather: "sunny", icon: require('@/assets/sunny.png')},
          {weather: "cloudy", icon: require('@/assets/cloudy.png')},
          {weather: "broken_clouds", icon: require('@/assets/broken_clouds.png')}
        ],

        weathers: null,
        key: null,
        amountOfDays: 7,
        DATE: new Date(),
      }
    },


  mounted()
    {
      //taking weather info from localhost
      fetch(`${process.env.VUE_APP_BASE_URL}:5000/OpenWeatherAPI`)
        .then(res => res.json())
        .then(data => this.weathers = data)
        .catch(err => console.log(err.message))

    },


  methods:
    {

      getDayName(index){
        //setting date to a numerical value depening on the day of the week
        const currentDay = this.DATE.getDay()

        // setting days array
        const days = ["Sunday","Monday","Tuesday","Wednesday", "Thursday",
        "Friday", "Saturday"]

        //the day that will be posted to the card
        let postedDay = currentDay + index

        // check if the posted day is over the amount of days in the week it
        // gets reset to zero
        if (postedDay >= this.amountOfDays)
          {
              //set postedDay to zero
              postedDay = postedDay - this.amountOfDays
          }

        //return the day of the week as a string
        return days[postedDay]
      },

    // TODO: fix bug with getDatePost where it will display months over. such as 6/32, 6/35 etc
    getDatePost(index)
      {
        let currentDate = this.DATE.getDate()

        //add index to currentDate to get an offset for future dates
        currentDate += index;
        return currentDate;
      },

    getMonthPost()
      {
        const MONTH_OFFSET = 1;
        let currentMonth = this.DATE.getMonth() + MONTH_OFFSET

        //months to be used if I decide to go with month names
        const months = ["January", "Febuary", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November",
                        "December"]

        //return current month number
        return currentMonth
      },

    getWeatherMain(index)
      {
        let weatherMainDesc = this.weathers.daily[index].weather[0].main
        return weatherMainDesc;
      },

    getWeatherDescription(index)
      {
        let weatherMainDesc = this.weathers.daily[index].weather[0].description
        return weatherMainDesc;
      },

    getWeatherIcon(index)
      {
        let weatherMainDesc = this.weathers.daily[index].weather[0].description
        let icon = "ht"

        if (weatherMainDesc.includes("rain"))
          {
            icon = this.Climates[0].icon
          }

        else if (weatherMainDesc.includes("clear"))
          {
            icon = this.Climates[1].icon
          }

        else if (weatherMainDesc.includes("scattered clouds"))
          {
            icon = this.Climates[2].icon
          }

        else if (weatherMainDesc.includes("broken clouds"))
          {
            icon = this.Climates[3].icon
          }
        return icon;
      }
  }
}
</script>

<style scoped>
/* gives the card spacing from the bottom */
.bottom
  {
    padding: 0px 0px 0px 0px;
  }
</style>
