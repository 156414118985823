
<template>
  <div class=" d-flex flex-column bg-image h-100">
    <slot></slot>
  </div>
</template>

<script>
export default
  {
    name: "Full"
  }
</script>

<style lang="css" scoped>
.bg-image
  {
    background-color: #000913;
  }

  *{
    color: #f8f8ff;
  }
</style>
