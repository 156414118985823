<template>
  <div class="box-shadow m-3">

    <div class="container bg-transparent rounded d-flex justify-content-center align-items-center">

      <a href="https://www.google.com/maps/d/viewer?mid=1AACbmFPlmIg66VlZbJVlo6FmYUPzWVRD&ll=34.66307797023285%2C-111.4367014186289&z=7">Treasure Map</a>
    </div>

    <div class="container d-flex justify-content-center align-items-center">

    </div>

  </div>
</template>

<script>
export default
  {
    name: 'Modal'
  }
</script>

<style scoped>

a:link  {text-decoration: none;}

a
  {
    font-size: 3rem;
  }

.box-shadow
  {
    box-shadow: 0px 1px 9px black;
  }

.blur-bg
  {
    filter: blur(9px);
    -webkit-backdrop-filter: blur(10px);
  }
</style>
