<template>
  <div class="container bg-dark rounded mt-3 mb-3">
    <Modal/>
    <div class="ps-5">
      <h1>CHANGELOG:</h1>
      <h2>8/4/21</h2>
      <p>Added an exploration layer to the treasure map. The explorartion layer
        should be used to add hiking routes, pictures, and/or a description of
        areas that have been explored. Hopefully this will give developers more
        information on areas they wish to visit.</p>

      <h2>7/30/21</h2>
      <h3>Areas/Problems added in 2020</h3>
      <ul>
        <li>Yuichiro Roof</li>
        <li>Feather Roof</li>
        <li>Choss Wall</li>
        <li>Backwards into sunset</li>
        <li>Puma Groomer</li>
        <li>Whale Boulder</li>
        <li>Fernow Roof</li>
        <li>Legends Never Dyno</li>
        <li>Snake Bait</li>
        <li>Moss Roof</li>
        <li>Tainted Gardens</li>
        <li>Overhaul Roof</li>
        <li>Pocket Wall</li>
      </ul>
    </div>
  </div>
</template>

<script>

import Modal from '@/components/Modal.vue'

export default {
  name: 'TreasureMapContainer',
  components: {
    Modal
  },
}
</script>

<style scoped>
*{
  color: white;
}
</style>
