<template>
   <div class="card bg-dark" style="width: 18rem;">
     <img class="card-img-top" :src="getImgUrl()" alt="Card image cap">
     <div class="card-body">
       <h5 class="card-title">{{ name }} </h5>
     </div>
   </div>
</template>

<script>
export default
  {
    name: 'AreaCard',
    props: ['name', 'desc', 'approach', 'img_file'],
    methods:
      {
          // method to get the file path to the sub area's img_file
          getImgUrl() {
           return require('../assets/'+ this.img_file)
      },
  }
}
</script>

<style scoped>

a:link  {text-decoration: none;}

a
  {
    font-size: 3rem;
  }

.box-shadow
  {
    box-shadow: 0px 1px 9px black;
  }

.blur-bg
  {
    filter: blur(9px);
    -webkit-backdrop-filter: blur(10px);
  }

.card-title
{
  color:white;
}
</style>
