<template lang="html">

  <main class="flex-shrink-0">
    <div class="flex-column">
      <div class="d-flex flex-wrap justify-content-center">
        <AreasContainer/>
      </div>
    </div>
  </main>
</template>


<script>

import AreasContainer from '@/components/AreasContainer.vue'

export default
  {
    name: "Areas",

    components:
      {
        AreasContainer
      }
  }
</script>

<style lang="css"  scoped>
.image
  {
    padding: 10px;
    width: 30em;
    max-width: 100%;
    height: 20%;
  }


</style>
