<template>

  <!-- v-if to check if sub_area is filled with json data. won't render if area
  is not filled with json data -->
  <div v-if="sub_area" class="container-fluid mt-3" >

     <div v-if="sub_area" class="d-flex">
       <router-link to="/areas">
          <p>All Areas</p>
       </router-link>

       <p>&#8592;</p>
       <router-link :to="{ name: 'AreaDetails', params: {id: sub_area.area_id} }">
         <p>{{sub_area.area_name}}</p>
       </router-link>

       <p>&#8592;</p>

       <router-link :to="{ name: 'SubAreaDetails', params: {id: sub_area.id} }">
         <p>{{sub_area.name}}</p>
       </router-link>
     </div>



    <h1 class="mt-3">{{sub_area.name}}</h1>

    <div class="row align-items-center justify-content-center">

      <div class="col-3">
        <SubAreaClimbsBox :sub_area_id="sub_area.id" class="smallitem"/>
      </div>

      <div class="col-7">
        <img class="front_img mt-4 bigitem" :src="getImgUrl()">
      </div>

      <div class="">
        <InfoBox :desc="sub_area.desc" :approach="sub_area.approach"/>
      </div>


    </div>

  </div>

</template>

<script>
import InfoBox from '@/components/InfoBox.vue'
import SubAreaClimbsBox from '@/components/SubAreaClimbsBox.vue'
export default
  {
    name: "SubAreaDetailsContainer",
     props: ['id', 'img_file'],
     components:
     {
       InfoBox,
       SubAreaClimbsBox
     },

     data(){
       return {
           sub_area: null,
       }

   },

   created()
   {

   },

     mounted()
       {
         // taking area info from localhost 5000
         fetch(`http://${process.env.VUE_APP_BASE_URL}:5000/sub_areas/${this.$route.params.id}` )

           // turning an object into json data
           .then(res => res.json())

           // populate areas array with data
           .then(data => this.sub_area = data)

           // fires if there is an error
           .catch(err => console.log(err.message))
       },

       methods:{

         // method to get the file path to the sub area's img_file
         getImgUrl() {
          return require('../assets/'+ this.sub_area.img_file)
       },

  }
}

</script>

<style lang="css" scoped>
.front_img{
  height: 480px;
  width: 720px;
}

a:link  {text-decoration: none;}

h1{
  text-align: center;
}

</style>
