<template>
  <div v-if="sub_area" class=" container bg-dark ">

    <!-- displays all the climbs for this specific sub area -->
     <div v-for=" climb in sub_area.climbs" :key="climb.id" class="d-flex align-items-center justify-content-center" >
       <router-link :to="{ name: 'ClimbDetails', params: { id: climb.id} }">

            <h5>{{ climb.name }} </h5>

       </router-link>

       <!-- &nbsp; represents a space -->
       <h5> &nbsp;{{ climb.grade }}</h5>

    </div>
  </div>

</template>

<script>
export default {
  name: "SubAreaClimbsBox",
  props: ["sub_area_id"],

  data()
  {
    return{
      sub_area: null
    }
  },

  mounted()
    {
      // taking area info from localhost 5000
      fetch(`http://${process.env.VUE_APP_BASE_URL}:5000/sub_areas/` + this.sub_area_id)

        // turning an object into json data
        .then(res => res.json())

        // populate areas array with data
        .then(data => this.sub_area = data)

        // fires if there is an error
        .catch(err => console.log(err.message))

    },



}


</script>

<style lang="css" scoped>

a:link { text-decoration: none; }

</style>
