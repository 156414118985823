<template>
  <TreasureMapContainer/>
</template>

<script>
import TreasureMapContainer from '@/components/TreasureMapContainer.vue'

export default
  {
    name: 'TresureMap',

    components:
      {
        TreasureMapContainer
      }
  }
</script>
