<template>
  <Full>
      <NavBar/>
        <router-view :key="$route.fullPath"/>
      <Footer/>
  </Full>

</template>

<script>
import NavBar from '@/components/NavBar.vue'
import MainContent from '@/components/MainContent.vue'
import Full from '@/components/Full.vue'
import Footer from '@/components/Footer.vue'

export default
  {
    name: 'App',

    components:
      {
        NavBar,
        MainContent,
        Full,
        Footer
      },
  }
</script>

<style lang="css" scoped>
.bg-image
  {
    background-color: #0b111f;
    min-height: 101vh;
    background-size: cover;
  }

  *{
    color: #f8f8ff;
  }
</style>
