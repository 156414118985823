<template lang="html">
  <div class="blur-bg background">

      <nav class="navbar navbar-expand-lg navbar-light bg-transparent ms-5 ">

        <div class="container-fluid">

          <router-link class="navbar-brand text-light" to="/">TeamChoss</router-link>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarNav" aria-controls="navbarNav"
                  aria-expanded="false" aria-label="Toggle navigation">

            <span class="navbar-toggler-icon"></span>

          </button>

          <div class="collapse navbar-collapse" id="navbarNav">

            <ul class="navbar-nav">

              <router-link class="nav-item nav-link text-light" to="/">Home</router-link>

              <router-link class="nav-item nav-link text-light" to="/treasuremap">Treasure Map</router-link>

              <router-link class="nav-item nav-link text-light" to="/gallery">Gallery</router-link>

              <router-link class="nav-item nav-link text-light" to="/areas">Areas</router-link>

            </ul>

          </div>
        </div>
      </nav>
  </div>
</template>

<script>
export default
  {
    name: 'NavBar',
  }
</script>

<style media="screen">
.navagation
  {
    box-shadow: 0px 1px 9px black;
  }

.blur-bg
  {
    box-shadow: 0px 1px 9px black;
  }

.background
  {
    background-color: #1b212e;
  }


</style>
