<template lang="html">
  <div class="d-flex flex-column align-items-center justify-content-start">
    <slot></slot>
  </div>
</template>

<script>
export default
  {
    name: "MainContent"
  }
</script>

<style>
</style>
