<template>
    <div v-for="area in areas" :key="area.id">

      <router-link :to="{ name: 'AreaDetails', params: { id: area.id } }">

         <AreaCard
         :name="area.name" :desc="area.desc" :approach="area.approach"
         :img_file="area.img_file"
         />

      </router-link>

    </div>
</template>

<script>
import { onMounted } from 'vue'
import AreaCard from '@/components/AreaCard.vue'

export default
  {
    name: 'AreasContainer',
    components:
      {
        AreaCard
      },

      setup(){
        onMounted(() => console.log('mounted'))
      },
  data()
    {
      return{
          areas: [],
        }
    },
  mounted()
    {
      // taking area info from localhost 5000
      fetch(`http://${process.env.VUE_APP_BASE_URL}:5000/areas`)

        // turning an object into json data
        .then(res => res.json())

        // populate areas array with data
        .then(data => this.areas = data)

        // fires if there is an error
        .catch(err => console.log(err.message))
    },
    

}

</script>

<style>
.card
  {
    box-shadow: 0px 1px 9px black;;
    margin: 50px 0 0 10px;
  }
</style>
