import { createRouter, createWebHistory } from 'vue-router'

import Home             from '../views/Home.vue'
import TreasureMap      from '../views/TreasureMap.vue'
import Gallery          from '../views/Gallery.vue'
import Areas            from '../views/Areas.vue'
import AreaDetails      from '../views/AreaDetails.vue'
import SubAreaDetails   from '../views/SubAreaDetails.vue'
import ClimbDetails     from '../views/ClimbDetails.vue'
import NotFound         from '../views/NotFound.vue'
import Test             from '../views/Test.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
const routes = [
  {
    path: '/treasuremap',
    name: 'TreasureMap',
    component: TreasureMap,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas
  },
  {
    path: '/areas/:id',
    name: 'AreaDetails',
    component: AreaDetails,
    props: true
  },
  {
    path: '/subareas/:id',
    name: 'SubAreaDetails',
    component: SubAreaDetails,
    props: true
  },
  {
    path: '/climb/:id',
    name: 'ClimbDetails',
    component: ClimbDetails,
    props: true
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
},
{
  path: '/test',
  name: 'Test',
  component: Test
},

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
