<template>
  <!-- v-if to check if area is filled with json data. won't render if area is
  not filled with json data -->
  <div v-if="climb" class="container-fluid mt-3 ">

    <div class="d-flex">
      <router-link to="/areas">
         <p>All Areas</p>
      </router-link>

      <p>&#8592;</p>
      <router-link :to="{ name: 'AreaDetails', params: {id: climb.area_id} }">
        <p>{{climb.area_name}}</p>
      </router-link>

      <p>&#8592;</p>

      <router-link :to="{ name: 'SubAreaDetails', params: {id: climb.sub_area_id} }">
        <p>{{climb.sub_area_name}}</p>
      </router-link>

      <p>&#8592;</p>

      <router-link :to="{ name: 'ClimbDetails', params: {id: climb.id} }">
        <p>{{climb.name}}</p>
      </router-link>
    </div>

    <h1 class="mt-3">{{ climb.name }}</h1>

      <div class="row align-items-center justify-content-center">

        <div class="col-3">
          <SubAreaClimbsBox :sub_area_id="climb.sub_area_id"/>
        </div>

        <div class="col-7">
          <img class="mt-4 front_img" :src="getImgUrl">
        </div>

        <div class="">
          <!-- climbing box details-->
          <ClimbDetailsBox
          :name="climb.name" :desc="climb.desc" :approach="climb.approach"
          :grade="climb.grade"
          />
        </div>
      </div>
    </div>

</template>

<script>
import ClimbDetailsBox from '@/components/ClimbDetailsBox.vue'
import SubAreaClimbsBox from '@/components/SubAreaClimbsBox.vue'
export default
{
  name: "ClimbDetailsContainer",
  components:
    {
      ClimbDetailsBox,
      SubAreaClimbsBox
    },
  props: ['id'],


  data()
    {

      return {
         climb: null,
         sub_area: null,
      }

    },

    async created()
    {
      this.fetchClimbData()
   },

   methods:
   {
       fetchClimbData()
      {

         // taking area info from localhost 5000
          fetch(`http://${process.env.VUE_APP_BASE_URL}:5000/climbs/${this.$route.params.id}` )

           // turning an object into json data
            .then(res => res.json())

           // populate areas array with data
            .then(data => this.climb = data)

           // fires if there is an error
          .catch(err => console.log(err.message))

      }
   },


      computed: {

        // method to get the file path to the climbs img_file
        getImgUrl() {
          return require('../assets/'+ this.climb.img_file)

        },
      }
    }
</script>

<style lang="css" scoped>

h1{
  text-align: center;
}

a:link  {text-decoration: none;}

.front_img{
  width: 720px;
  height: 480px;


}

</style>
