<template>
<AreaDetailsContainer/>
</template>

<script>
import AreaDetailsContainer from '@/components/AreaDetailsContainer.vue'


export default {
  name: "AreaDetails",
  components:
  {
    AreaDetailsContainer,
  },

}

</script>

<style lang="css" scoped>

</style>
