<template lang="html">
  <div class="">
    <div class="">
          <DescBox :desc="desc" />
    </div>

    <div class="">
      <ApproachBox :approach="approach" />
    </div>

  </div>

</template>

<script>
import DescBox from '@/components/DescBox.vue'
import ApproachBox from '@/components/ApproachBox.vue'
export default {
  name: "InfoBox",
  props: [ 'desc', 'approach'],
  components:
  {
    DescBox,
    ApproachBox
  },
}
</script>

<style lang="css" scoped>
</style>
