<template>

<!-- v-if to check if area is filled with json data. won't render if area is
not filled with json data -->
<div v-if="area" class="container-fluid ">
  <div class="d-flex mt-3">
   <router-link to="/areas">
      <p class=""> All Areas</p>
   </router-link>

   <p>&#8592;</p>
     <router-link :to="{ name: 'AreaDetails', params: {id: area.id} }">
       <p>{{area.name}}</p>
     </router-link>
   </div>

  <h1 class="mt-3">{{area.name}}</h1>

  <!-- div containing the pages img and related sub areas box -->
  <div class="row align-items-center justify-content-center">

    <div class="col-2">
      <AreaSubAreasBox :sub_areas="area.sub_areas" class="smallitem"/>
    </div>

    <div class="col-7">
      <img class="front_img mt-4 mb-3 bigitem" :src="getImgUrl()">
    </div>

    <InfoBox :desc="area.desc" :approach="area.approach"/>

  </div>
</div>

</template>

<script>
import AreaSubAreasBox from '@/components/AreaSubAreasBox.vue'
import InfoBox from '@/components/InfoBox.vue'

export default
  {
    name: "AreaDetailsContainer",
    components:
    {
      AreaSubAreasBox,
      InfoBox
    },
     props: ['id'],
     data()
       {
         return {
             area: null,
             file: null
           }
       },
      mounted() {
         // taking area info from localhost 50003
        fetch(`http://${process.env.VUE_APP_BASE_URL}:5000/areas/` + this.id )

           // turning an object into json data
           .then(res => res.json())

           // populate areas array with data
           .then(data => this.area = data)

           // fires if there is an error
           .catch(err => console.log(err.message))
       },
       methods:
        {
           // method to get the file path to the areas img_file
           getImgUrl()
             {
               return require('../assets/'+ this.area.img_file)
             }
        }
  }

</script>

<style lang="css" scoped>
.front_img{
  height: 480px;
  width: 720px;
}

h1{
  text-align: center;
}

a:link  {text-decoration: none;}
.bigitem { flex: 0 0 80%; } /* flex-grow, flex-shrink, flex-basis */

.smallitem { flex: 0 0 15%; }
</style>
