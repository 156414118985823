<template>
  <SubAreaDetailsContainer/>

</template>

<script>
import SubAreaDetailsContainer from '@/components/SubAreaDetailsContainer.vue'
export default
  {
    name: "SubAreaDetails",
    components:
     {
       SubAreaDetailsContainer
     },

}

</script>

<style lang="css" scoped>

</style>
