<template>
  <div class="m-5">
    <h5>Approach:</h5>
    <p>{{approach}}</p>
  </div>


</template>

<script>
export default {
  name: "ApproachBox",
  props: ["approach"]
}
</script>

<style lang="css" scoped>
</style>
