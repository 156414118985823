<template>

  <ClimbDetailsContainer/>

</template>

<script>
import ClimbDetailsContainer from '@/components/ClimbDetailsContainer.vue'

export default
{
  name: "ClimbDetails",
  props: ['sub_id'],
  components:
    {
      ClimbDetailsContainer
    }
}

</script>

<style lang="css" scoped>

</style>
