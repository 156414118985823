<template lang="html">
    <div class="card" style="width: 18rem;">
      <div class="card-body">

        <h5 ref = "day" class="card-title">{{ day }} {{ month }}/{{ date }} </h5>

        <h5 ref = "date" class="card-title">  </h5>

        <h5 ref = "description" class="card-title"></h5>

        <h5 class="card-text">High: {{ high }} </h5>

        <h5 class="card-text">Low: {{ low }} </h5>

        <div class="fluid-container">

          <h5 class="card-text">{{ description }}</h5>

          <img :src="icon" alt="">

          <i class="wi wi-day-sunny"></i>

        </div>

      </div>
    </div>
</template>

<script>

export default
{
  name: 'WeatherCard',
  props: ['high', 'low', 'day', 'img', 'date', 'month', 'main', 'description', 'icon']
}


</script>

<style scoped>
.card
  {
    box-shadow: 0px 1px 9px black;;
    margin: 50px 0 0 10px;
  }

* {
  color: black;
}
</style>
