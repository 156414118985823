<template lang="html">
  <footer class="footer mt-auto py-3 bg-dark">
  <div class="container">
    <span class="text-muted foot"> Northern Arizona Archive </span>
  </div>
</footer>

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="css" scoped>

.foot {
  text-align: center;
}
span {
  text-align: center;
}

</style>
