<template lang="html">
<div v-if="climbResults">
  <h1>{{climbResults.name}}</h1>
</div>


<h1>{{subResults.name}}</h1>

</template>

<script>
import { ref } from 'vue'

export default {
   name: "Test",
   setup()
   {


     let climbResults = ref([])
     let climbResultz = ref([])
     const climbError = ref(null)
     let subResults = ref([])
     const subError = ref(null)

     const load = async () => {

       await fetch(`https://${process.env.BASE_URL}:5000/climbs/3`)
       .then((res) => res.json())
       .then((data) => climbResults.value = data)
       .then((data) => climbResultz = data)
       .catch(err => console.log(err.message))



       loadSub()
     }



     const loadSub = async () => {
       console.log(climbResultz.id)
       try {
         let data = await fetch(`https://${process.env.VUE_APP_BASE_URL}:8000/sub_areas/` + climbResultz.sub_area_id)
         if (!data.ok) {
           throw Error('no data available')
         }
         subResults.value = await data.json()
       }
       catch (err){
         subError.value = err.message
       }
     }

     load()

     return {climbResults, subError, climbError, subResults}
   }
}
</script>

<style lang="css" scoped>
</style>
