<template>
  <div class="container bg-transparent mt-3 mb-3 border border-grey ">
    <div class="row">

      <div class="col-sm border-end border-grey d-flex align-items-center
      justify-content-center">
        <h1>{{grade}}</h1>
      </div>

      <!-- TODO: use div as divider -->

      <div class="col-sm">
        {{desc}}
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "ClimbDetailsBox",
  props: ['name', 'desc', 'approach', 'grade'],
}
</script>

<style lang="css" scoped>
.border-grey{
  border-color: rgba(255, 255 ,255 , 0.5) !important;
}

</style>
